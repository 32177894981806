.video-js {

  .vjs-http-source-selector {

    .vjs-button {
      font-size: 17px;
    }

  }
}
